import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from "react-use";
import globals from "../globals";

const Heading = (props) => {

    const isMobile = useMedia('(max-width: 992px)');

    return (
        <>
            <div {...props} className={props.className}>
                <props.type dangerouslySetInnerHTML={{__html: isMobile || props.ignoreWidows ? props.children : globals.noWidows(props.children)}} />
            </div>
        </>
    );

};

Heading.propTypes = {
    type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']).isRequired,
    ignoreWidows: PropTypes.bool
};

Heading.defaultProps = {
    className: ''
};

export default Heading;