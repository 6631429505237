import React from "react";
import Section from "./section";
import styles from "./bottom.module.scss";
import Heading from "../components/heading";
import Subscribe from "../components/subscribe";
import globals from "../globals";
import image from "../assets/images/bg-bottom.svg";
import { gsap } from "gsap";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
import loader from "../assets/images/loader.svg";
import iconClose from "../assets/images/icon-close.svg";
//import "../plugins/debug.addIndicators";

class Bottom extends React.Component {

    constructor(props) {
        super(props);

        this.subscribeRef = React.createRef();
        this.headingRef = React.createRef();
        this.contentRef = React.createRef();
        this.subscribeBoxRef = React.createRef();
        this.containerRef = React.createRef();
        this.loaderRef = React.createRef();
        this.successRef = React.createRef();

        this.tl = gsap.timeline();
    }

    componentDidMount() {
        if (!this.props.isMobile) {
            this.animateOnScroll();
        }
    }

    animateOnScroll() {
        const controller = new ScrollMagic.Controller();
        const tl = new gsap.timeline();
        tl.fromTo(this.subscribeRef.current, {y: -100}, {y: 0, duration: 2});
        tl.fromTo(this.headingRef.current, {y: -50}, {y: 0, duration: 2}, 0);
        tl.fromTo(this.contentRef.current, {y: -30}, {y: 0, duration: 2}, 0);
        tl.fromTo(this.subscribeBoxRef.current, {y: -10}, {y: 0, duration: 2}, 0);

        new ScrollMagic.Scene({
            triggerElement: document.getElementById('bottom-section'),
            triggerHook: 'onLeave',
            duration: 800,
            offset: -600
        })
            .setTween(tl)
            //.addIndicators({name: 'bottom'})
            .addTo(controller);
    }

    handleSubmit() {
        this.tl.clear();
        this.tl.set(this.containerRef.current, {overflow: 'hidden'});
        this.tl.to(this.containerRef.current, {scale: 0, duration: 0.3, ease: 'power4.out'});
        this.tl.set(this.containerRef.current, {opacity: 0, overflow: 'visible'});
        this.tl.set(this.loaderRef.current, {display: 'flex', opacity: 0, scale: 0});
        this.tl.to(this.loaderRef.current, {opacity: 1, scale: 1, duration: 0.2});
    }

    handleSuccess() {
        this.tl.clear();
        this.tl.set(this.successRef.current, {display: 'flex', opacity: 0});
        this.tl.to(this.loaderRef.current, {opacity: 0, duration: 0.2});
        this.tl.to(this.successRef.current, {opacity: 1, duration: 0.2}, 0.1);
    }

    handleClickClose() {
        this.tl.clear();
        this.tl.to(this.successRef.current, {opacity: 0, duration: 0.2});
        this.tl.set([this.successRef.current, this.loaderRef.current], {display: 'none'});
        this.tl.to(this.containerRef.current, {opacity: 1, scale: 1, duration: 0.3});
    }

    render() {
        return (
            <Section className={styles.bottom} innerClassName={styles.bottomInner} id="bottom-section">
                <div className={styles.imageWrap}>
                    <img src={image} alt="Hostelify" className={styles.image} />
                </div>
                <div ref={this.subscribeRef} className={styles.container}>
                    <div className="row d-flex justify-content-center">
                        <div ref={this.containerRef} className="col-12 text-center">
                            <div ref={this.headingRef}>
                                <Heading type="h4" className="mb-3">Keep up to date</Heading>
                            </div>
                            <div ref={this.contentRef}>
                                <p className="mb-5" dangerouslySetInnerHTML={{__html: globals.noWidows('Like what you hear? Awesome! Throw your email in here and be the first to hear about upcoming releases, new features and more.')}} />
                            </div>
                            <div ref={this.subscribeBoxRef}>
                                <Subscribe
                                    isMobile={this.props.isMobile}
                                    handleSubmit={this.handleSubmit.bind(this)}
                                    handleSuccess={this.handleSuccess.bind(this)}
                                />
                            </div>
                        </div>
                        <div ref={this.loaderRef} className={styles.overlay} style={{display: 'none'}}>
                            <img src={loader} alt="loading..." width="50px" />
                        </div>
                        <div ref={this.successRef} className={styles.overlay} style={{display: 'none'}}>
                            <div className="p-5 text-center">
                                <h4 className="m-0 mb-2">All sorted!</h4>
                                <div className={styles.line} />
                                <p className="m-0">Thanks for joining our community, you will be the first to hear about our upcoming releases and features so keep an eye out for our emails.</p>
                            </div>
                            <button className={styles.overlayClose} onClick={this.handleClickClose.bind(this)}>
                                <img src={iconClose} alt="Close" />
                            </button>
                        </div>
                    </div>
                </div>
            </Section>
        );
    }

}

export default Bottom;