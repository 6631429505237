import React from "react";
import PropTypes from "prop-types";
import Button from "./button";
import styles from "./subscribe.module.scss";
import { gsap } from "gsap";
import validator from 'validator';

class Subscribe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            error: false
        };

        this.nameField = React.createRef();
        this.nameInput = React.createRef();
        this.emailField = React.createRef();
        this.tl = gsap.timeline();
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleEnterPress(event, onKeyPress) {
        if (event.keyCode === 13) {
            event.preventDefault();
            onKeyPress(event);
        }
    }

    handleSubmit(e) {
        this.setState({error: false});
        if (this.validateField('name')) {
            e.preventDefault();
            this.props.handleSubmit();
            this.resetTl();
            setTimeout(() => {
                fetch('/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: this.encode({ 'form-name': 'subscribe', ...this.state })
                })
                    .then(() => {
                        this.props.handleSuccess();
                        this.setState({
                            email: '',
                            name: ''
                        });
                    })
                    .catch(error => {
                        //todo: Handle failure
                    });
            }, 2000);
        } else {
            this.setState({error: 'Please enter your name'});
        }
    }

    handleNext() {
        this.setState({error: false});
        if (this.validateField('email')) {
            const tl = gsap.timeline();
            tl.set(this.nameField.current, {display: 'inline-flex', opacity: 0, x: 200});
            if (!this.props.isMobile) {
                tl.add(() => {
                    this.nameInput.current.focus();
                });
            }
            tl.to(this.emailField.current, {x: -200, opacity: 0, ease: 'power4.out', duration: 0.75});
            tl.to(this.nameField.current, {x: 0, opacity: 1, ease: 'power4.out', duration: 1}, '-=0.4');
        } else {
            this.setState({error: 'Please enter a valid email address'});
        }
    }

    validateField(name) {
        if (name === 'email') {
            return validator.isEmail(this.state.email);
        } else {
            return !validator.isEmpty(this.state.name, {ignore_whitespace: true});
        }
    }
    
    resetTl() {
        gsap.set(this.nameField.current, {display: 'none', opacity: 1, x: 0});
        gsap.set(this.emailField.current, {x: 0, opacity: 1});
    }

    encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    render() {
        return (
            <form
                name="subscribe"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit.bind(this)}
            >
                <div className={styles.mainContainer}>
                    <div ref={this.emailField} className={styles.container}>
                        <input
                            value={this.state.email}
                            name="email"
                            placeholder="Your email address"
                            className={styles.input}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={event => {
                                this.handleEnterPress(event, this.handleNext.bind(this));
                            }}
                        />
                        <Button handleClick={this.handleNext.bind(this)} className={styles.button}>Next</Button>
                    </div>
                    <div ref={this.nameField} className={`${styles.container} ${styles.nameField}`} style={{display: 'none'}}>
                        <div className={styles.and}>
                            and
                        </div>
                        <input
                            ref={this.nameInput}
                            value={this.state.name}
                            name="name"
                            placeholder="Your name"
                            className={styles.input}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={event => {
                                this.handleEnterPress(event, this.handleSubmit.bind(this));
                            }}
                        />
                        <Button handleClick={this.handleSubmit.bind(this)} className={styles.button}>Send</Button>
                    </div>
                </div>

                {this.state.error ?
                    <div className="mt-2">
                        {this.state.error}
                    </div>
                : ''}
            </form>
        );
    }

}

Subscribe.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired
};

export default Subscribe;